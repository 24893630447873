<template>
    <v-main>
        <section>
            <v-layout column wrap class="my-12" align-center>
                <v-flex xs12 sm4 class="my-4">
                    <div class="text-center">
                        <h2 class="headline">
                            Lola Groups - Easy and safe online tour bookings
                        </h2>
                        <p class="subheading">
                            Lola Groups is a service brought to you by Lolaguide
                            Internet Inc., a Canadian software startup focused
                            on making travel easier so everyone can experience
                            the best of the world.
                        </p>
                        <br>
                        <a href="#!" @click="goBack()">Click here to go back</a>
                    </div>
                </v-flex>
            </v-layout>
        </section>
    </v-main>
</template>

<script>
export default {
    name: 'About',
    methods: {
        goBack() {
            window.history.back()
        },
    },
}
</script>
